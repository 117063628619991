import React, { memo } from "react"
import DaumPostcode from "react-daum-postcode"

export type DaumAddressResult = {
  zonecode: string
  address: string
  addressEnglish: string
  addressType: "R" | "J"
  userSelectedType: "R" | "J"
  userLanguageType: "K" | "E"
  roadAddress: string
  roadAddressEnglish: string
  jibunAddress: string
  jibunAddressEnglish: string
  autoRoadAddress: string
  autoRoadAddressEnglish: string
  autoJibunAddress: string
  autoJibunAddressEnglish: string
  buildingCode: string
  buildingName: string
  apartment: "Y" | "N"
  sido: string
  sigungu: string
  sigunguCode: string
  roadnameCode: string
  bcode: string
  roadname: string
  bname: string
  bname1: string
  bname2: string
  hname: string
  query: string
  postcode: string
  postcode1: string
  postcode2: string
  postcodeSeq: string
  noSelected: "Y" | "N"
}

type DaumPostcodeProps = {
  onComplete: (data: DaumAddressResult) => void
  onSearch: (data: object) => void
  alwaysShowEngAddr?: boolean
  animation?: boolean
  autoClose?: boolean
  autoMapping?: boolean
  autoResize?: boolean
  defaultQuery?: string
  errorMessage?: React.ReactElement<any>
  height?: number | string
  hideEngBtn?: boolean
  hideMapBtn?: boolean
  maxSuggestItems?: number
  pleaseReadGuide?: number
  pleaseReadGuideTimer?: number
  scriptUrl?: string
  shorthand?: boolean
  showMoreHName?: boolean
  style?: object
  theme?: object
  useSuggest?: boolean
  width?: number | string
  zonecodeOnly?: boolean
  submitMode?: boolean
  focusInput?: boolean
  focusContent?: boolean
}

export default memo((props: DaumPostcodeProps) => {
  return <DaumPostcode {...props} />
})
