import React, { memo, useCallback, useRef } from "react"
import YouTube from "react-youtube"
import styled from "../context/theme"

const Self = styled.div`
  width: 100%;
  height: 100%;
  label: YouTubeWrapper;

  @media (min-width: ${props => props.theme.size.maxWidth}px) {
    width: 640px;
    height: 360px;
  }

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    position: absolute;
    top: 0;
    left: 0;
  }

  & > div {
    width: 100%;
    height: 100%;
  }
`

interface PlayerOptions {
  height?: string
  playerVars?: {
    // https://developers.google.com/youtube/player_parameters
    autoplay?: 0 | 1
    cc_lang_pref?: string
    color?: "red" | "white"
    controls?: 0 | 1
    disablekb?: 0 | 1
    enablejsapi?: 0 | 1
    list?: string
    listType?: "playlist" | "search" | "user_uploads"
    modestbranding?: 1
    playlist?: string
    playsinline?: 0 | 1
    rel?: 0 | 1
    start?: number
    widget_referrer?: string
  }
  width?: string
}

export default memo(
  ({
    className,
    onReady,
    playerOptions,
    videoId,
  }: {
    className?: string
    onReady?: (any) => void
    playerOptions: PlayerOptions
    videoId: string
  }) => {
    const r = useRef(null)
    const _onReady = useCallback(e => {
      onReady(e.target)
    }, [])

    return (
      <Self className={className}>
        <YouTube onReady={_onReady} opts={playerOptions} videoId={videoId} />
      </Self>
    )
  }
)
