import React, { HTMLAttributes, HtmlHTMLAttributes, memo } from "react"
import styled from "../context/theme"

const StyledSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  label: SectionWrapper;
`

const StyledInner = styled.div`
  max-width: ${props => props.theme.size.maxWidth}px;
`

export const InnerSection: React.FC<HTMLAttributes<
  HTMLElement
>> = memo(({ children, ...props }) => (
  <StyledInner {...props}>{children}</StyledInner>
))

export const SectionWrapper: React.FC<
  HTMLAttributes<HTMLElement> & {
    register?: React.MutableRefObject<any>
  }
> = memo(({ children, register, ...props }) => {
  return (
    <StyledSectionWrapper ref={register} {...props}>
      {children}
    </StyledSectionWrapper>
  )
})
