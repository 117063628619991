import React, { memo, useCallback } from "react"
import { css } from "@emotion/core"
import styled from "../../context/theme"
import Input from "../form/input"
import { DeepMap, FieldError } from "react-hook-form"
import { useModalStore } from "../../context/stores"
import DaumPostCodeOrig, { DaumAddressResult } from "../postcode"
import { Modal as ModalOrig } from "../modals"

const Self = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  max-width: 500px;
  padding-bottom: 50px;
`

const InputStyle = css`
  background-color: white;
`

const LabelStyle = css`
  font-size: 16px;
  line-height: 22px;
  color: #353535;
`

const DaumPostCode = styled(DaumPostCodeOrig)`
  label: DaumPostCode;
`

const Modal = styled(ModalOrig)``

const NameInput = styled(Input)`
  width: calc(100% / 2 - 50px);
`

const NamePostalRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const PostalCodeRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: calc(100% / 2 - 10px);
`
const PostalCodeInputWrapper = styled(Input)``

const PostalCodeInputStyle = css`
  width: 140px;
`

const AddressInput = styled(Input)`
  margin-top: 30px;
`

const AddressDetailInput = styled(Input)`
  margin-top: 30px;
`

export default memo(
  ({
    address_common,
    errors,
    postal_code,
    onSearch,
    register,
  }: {
    address_common?: string
    errors?: DeepMap<Record<string, any>, FieldError>
    postal_code?: string
    onSearch?: (parms: DaumAddressResult) => void
    register: React.Ref<HTMLInputElement>
  }) => {
    const { closeModal, openModal } = useModalStore()

    const handleSearch = useCallback(
      (data: DaumAddressResult) => {
        onSearch(data)
        closeModal()
      },
      [closeModal, onSearch]
    )

    const handleClickPostalCodeSearch = useCallback(() => {
      openModal(
        <Modal>
          <DaumPostCode onComplete={handleSearch} onSearch={() => {}} />
        </Modal>
      )
    }, [onSearch])

    return (
      <Self>
        <NamePostalRow>
          <NameInput
            errorMessage={errors.name?.message}
            inputStyle={InputStyle}
            label="이름"
            labelStyle={LabelStyle}
            name="name"
            register={register}
          />

          <PostalCodeRow>
            <PostalCodeInputWrapper
              buttonAction={handleClickPostalCodeSearch}
              buttonText="검색"
              disabled
              inputStyle={PostalCodeInputStyle}
              label="우편번호"
              labelStyle={LabelStyle}
              name="postal_code"
              register={register}
              value={postal_code}
              withButton
            />
          </PostalCodeRow>
        </NamePostalRow>

        <AddressInput
          disabled
          label="기본주소"
          labelStyle={LabelStyle}
          name="address_common"
          register={register}
          value={address_common}
        />

        <AddressDetailInput
          inputStyle={InputStyle}
          label="상세주소"
          labelStyle={LabelStyle}
          name="address_append"
          register={register}
        />
      </Self>
    )
  }
)
