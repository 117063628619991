import React, { memo } from "react"

import { InnerSection as InnerSectionOrig, SectionWrapper } from "./common"
import styled from "../context/theme"

const Self = styled(SectionWrapper)`
  background-image: url("images/section-plastic-gray.jpg");
  background-size: cover;
  padding: 49px 0px 70px;
`

const Description = styled.p`
  color: ${props => props.theme.colors.white};
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  margin-top: 53px;
  word-break: keep-all;
`

const InnerSection = styled(InnerSectionOrig)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 53px;
  max-width: ${props => props.theme.size.maxWidth}px;
  label: DotfacePeople;
`

const Title = styled.h2`
  color: white;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
`

export default memo(() => {
  return (
    <Self>
      <InnerSection className="ga-scroll" data-ga-section="닷페피플 알아보기">
        <Title>
          코로나19로 폭증한
          <br />
          플라스틱 쓰레기, <br />
          <br />
          10개 중 2개만이 실제
          <br />
          재활용되는 폐플라스틱.
        </Title>

        <Description>
          닷페이스가 플라스틱 환경 문제를
          <br />
          계속 취재할 수 있도록
          <br />
          후원자로 함께해주세요.
        </Description>
      </InnerSection>
    </Self>
  )
})
