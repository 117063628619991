import React, { memo, useCallback, useEffect, useRef, useState } from "react"
import throttle from "lodash/throttle"
import { css } from "@emotion/core"
import styled from "../context/theme"
import PlayerOrig from "./player"
import CarouselOrig, { Scene } from "../components/carousel"
import { InnerSection as InnerSectionOrig } from "./common"

const Self = styled.section`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  padding: 0px 0 50px;
  position: relative;
  label: JumbotronSection;
`

const Carousel = styled(CarouselOrig)`
  padding-top: calc(61% + 20px);

  @media (min-width: 500px) {
    padding-top: calc(70% + 20px);
  }

  @media (min-width: 640px) {
    padding-top: 420px;
  }
`

const Logo = styled.div`
  width: 75px;
  height: 28px;
  background-image: url("images/logo-white.png");
  background-size: cover;

  @media (min-width: 500px) {
    width: 112px;
    height: 42px;
  }
`

const LogoBand = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: #561496;

  @media (min-width: 500px) {
    height: 60px;
  }
`

const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #000000;
  z-index: 1001;
  left: 0;
  width: 100%;

  ${(props: { fixed: boolean }) =>
    props.fixed
      ? css`
          position: fixed;
          top: 0;
        `
      : css`
          position: absolute;
          top: none;
          bottom: 0;
          right: 0;
        `}
`

const InnerSection = styled(InnerSectionOrig)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: ${props => props.theme.size.maxWidth}px;

  @media (min-width: ${props => props.theme.size.maxWidth}px) {
    width: 640px;
    height: 360px;
  }

  @media (max-width: ${props => props.theme.size.maxWidth - 1}px) {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
  }
`

const Player = styled(PlayerOrig)``

const scenes: Scene[] = [
  {
    timeFrom: 65,
    title: "플라스틱은 재활용 사절?",
    description:
      "플라스틱 폐기물을 직접 재활용 수거업체에 가져가 봤습니다. 플라스틱을 담은 봉지임을 확인한 관계자들은 입구에서부터 '딴 데 버려라', '안 가져간다'라는 말을 했습니다. 재활용하면 돈이 된다는 플라스틱을 이들은 왜 거부할까요?",
  },
  {
    timeFrom: 267,
    title: "500톤짜리 쓰레기 장벽",
    description:
      '"코로나가 터지고부터 쌓였으니까."\n\n재활용 선별장에는 저 멀리 500톤의 플라스틱 폐기물이 장벽처럼 쌓여있었습니다. 선별장은 플라스틱을 종류, 색깔별로 분류해 재활용 원료 생산 업체로 넘기는 곳입니다. 깨끗하고 재활용이 잘 되는 플라스틱만 엄선해놨지만 가져가는 곳이 없어 그대로 쌓아놓을 수밖에 없었다고 합니다.',
  },
  {
    timeFrom: 482,
    title: "재활용이 더 비싸요",
    description:
      '"고유가 시대엔 플라스틱 재활용이 돈이 됐죠"\n재활용이 안 되는 가장 큰 이유는 돈이었습니다. 석유로 만드는 플라스틱은 국제유가에 따라 가격이 달라집니다. 기름값이 비쌀 땐 플라스틱 값이 비싸니 재활용 플라스틱도 돈이 됐다고 합니다. 그런데 지금은 기름값이 워낙 싸서 플라스틱을 재활용해서 팔아봤자 남는 게 없다는 말들을 들을 수 있었습니다.',
  },
  {
    timeFrom: 673,
    title: "커피 컵은 다 쓰레기",
    description:
      '"고르는 인건비에 비해서 남는 게 없으니까 안 골라"\n\n우리가 카페에서 받는 일회용 플라스틱 컵은 거의 다 쓰레기로 버려지고 있었습니다. 종류가 저마다 다르기 때문에 일일이 손으로 분류해야 하는데, 막상 그렇게 재활용해봤자 남는 게 없기 때문이라고 합니다. 이처럼 우리가 재활용될 줄 알았던 많은 것들이 실제 현장에선 경제 논리에 따라 쓰레기로 버려지고 있었습니다',
  },
  {
    timeFrom: 803,
    title: "10개 중 7개는 태운다?",
    description:
      "\"우리가 플라스틱을 10개를 버리면, 그중에 2개만 실제로 (물질) 재활용이 돼요\"\n\n이게 무슨 소리일까요? 환경부에 따르면 한국의 플라스틱 재활용률은 60% 가까이 됩니다. 그런데 이 중 플라스틱 폐기물이 다시 재생 플라스틱으로 활용되는 비율은 1/3 정도입니다. 나머지 2/3는 공장이나 발전소의 연료로 쓰입니다. 석탄 대신 플라스틱을 연료로 태우는 방식입니다. '재활용'이 플라스틱을 '태우는 방식'으로 이뤄지고 있었던 겁니다.",
  },
  {
    timeFrom: 896,
    title: "팔지도 태우지도 못하면",
    description:
      "플라스틱을 태워 연료로 쓰는 방식. 이 ‘열 회수’ 재활용은 환경오염 때문에 논란이 많지만, 어쩔 수 없는 현실적 대안이기도 합니다. 정부는 플라스틱 처리를 위해 이 방식을 확대하려 해왔습니다. 지난 10년간 고형연료 발전소를 전국 곳곳에 건설했죠.\n\n그런데 발전소 완공을 앞두고 지역 주민의 반대에 부딪히면서 전국의 고형연료 발전소 가동이 무산됐습니다. 대량의 플라스틱 쓰레기를 처리할 계획이 틀어진 겁니다. 그야말로 ‘쓰레기 대란’이 눈앞에 다가왔습니다.",
  },
  {
    timeFrom: 976,
    title: "믿었는데, 사기였다",
    description:
      '"저는 소비자들이 사기당했다고 생각해요"\n\n자원순환경제연구소 홍수열 소장은 소비자들이 정부에게 사기를 당했다고 말합니다. 당연히 재활용될 줄 알았던 것 중 많은 양이 버려지거나 태워지고 있었습니다. 이제는 그마저도 안 돼서 어딘가에 쌓는 것 외엔 방법이 없습니다.',
  },
]

const timeRanges = [0, ...scenes.map(scene => scene.timeFrom)]

export default memo(({ start = 0 }: { start?: number }) => {
  const [initialized, setInitialized] = useState(false)
  const selfRef = useRef(null)
  const videoRef = useRef(null)
  const [videoFixed, setVideoFixed] = useState(true)
  const carouselPageRef = useRef(0)
  const [carouselPage, setCarouselPage] = useState(0)
  const playerRef = useRef(null)
  const timerRef = useRef(null)
  const manualSeekingRef = useRef(false)
  const manualSeekingTimerRef = useRef(null)
  const scrollEventRef = useRef(null)

  const handleTimerFlow = useCallback(() => {
    if (
      playerRef.current &&
      !manualSeekingRef.current &&
      playerRef.current.getPlayerState() === 1
    ) {
      const currentPlayingTime = playerRef.current.getCurrentTime()

      for (let i = 0; i < timeRanges.length; i++) {
        const page = i - 1 <= 0 ? 0 : i - 1
        if (
          currentPlayingTime >= timeRanges[i] &&
          currentPlayingTime < timeRanges[i + 1] &&
          carouselPageRef.current !== page
        ) {
          carouselPageRef.current = page
          setCarouselPage(page)
          break
        }
      }
    }
  }, [setCarouselPage])

  useEffect(() => {
    scrollEventRef.current = throttle(() => {
      if (selfRef.current) {
        const selfRect = selfRef.current.getBoundingClientRect()
        const videoRect = videoRef.current.getBoundingClientRect()

        if (selfRect.height + selfRect.top < videoRect.height) {
          setVideoFixed(false)
        } else {
          setVideoFixed(true)
        }
      }
    }, 100)

    window.addEventListener("scroll", scrollEventRef.current)
    ;() => {
      if (timerRef.current) {
        window.clearInterval(timerRef.current)
        timerRef.current = null
      }

      if (scrollEventRef.current) {
        window.removeEventListener("scroll", scrollEventRef.current)
        scrollEventRef.current = null
      }
    }
  }, [])

  useEffect(() => {
    if (start) {
      for (let i = 0; i < timeRanges.length; i++) {
        const page = i - 1 <= 0 ? 0 : i - 1
        if (start >= timeRanges[i] && start < timeRanges[i + 1]) {
          setTimeout(() => {
            carouselPageRef.current = page
            setCarouselPage(page)
          }, 1000)
          break
        }
      }
    }
  }, [])

  const handleChangePage = useCallback(
    page => {
      if (!initialized) {
        setInitialized(true)
      }

      if (initialized) {
        carouselPageRef.current = page
        setCarouselPage(page)

        manualSeekingRef.current = true
        manualSeekingTimerRef.current = window.setTimeout(() => {
          manualSeekingRef.current = false
        }, 1000 * 30)
      }
    },
    [initialized, setCarouselPage, setInitialized]
  )

  const handleReady = useCallback(player => {
    playerRef.current = player
    timerRef.current = window.setInterval(handleTimerFlow, 1000)
  }, [])

  const onPlayTime = useCallback(time => {
    playerRef.current.seekTo(time)
  }, [])

  return (
    <Self ref={selfRef}>
      <VideoWrapper fixed={videoFixed} ref={videoRef}>
        <LogoBand>
          <Logo />
        </LogoBand>
        <InnerSection>
          <Player
            onReady={handleReady}
            playerOptions={{
              playerVars: {
                enablejsapi: 1,
                modestbranding: 1,
                list: "PLo4PbCH1mmVc2H2zBnsTFCy-crRgw5dA6",
                listType: "playlist",
                playlist: "7bMFYdcOXEY",
                playsinline: 1,
                rel: 1,
                start,
                widget_referrer: "https://plastic.dotface.kr",
              },
              height: "100%",
              width: "100%",
            }}
            videoId="nZk8Vcz4vUY"
          />
        </InnerSection>
      </VideoWrapper>

      <Carousel
        onChangePage={handleChangePage}
        onPlayTime={onPlayTime}
        page={carouselPage}
        scenes={scenes}
      />
    </Self>
  )
})
