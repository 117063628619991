import { css } from "@emotion/core"
import React, { memo, useCallback, useEffect, useState } from "react"
import styled from "../../context/theme"
import Input from "./input"

const AMOUNTS = [11000, 20000, 30000]

const Self = styled.div``

const LabelStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f6f9;
  width: calc(100% / 4 - 5px);
  height: 33px;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  padding-top: 4px;
  border: 1px solid #a8a4ac;
  margin-left: 9px;
  padding: 0;
  font-size: 15px;
`

const CustomAmountButton = styled.div`
  ${LabelStyle}

  ${(props: { checked: boolean }) =>
    props.checked &&
    css`
      border: 2px solid #561496;
      background-color: #e8e6eb;
    `}
`

const CustomAmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 10px;
`

const CustomAmountInput = styled(Input)`
  width: 143px;
`

const CustomAmountInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
`

const CustomAmountHelpText = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 22.4px;
`

const FixedAmountInput = styled.input`
  display: none;
`

const FixedAmountLabel = styled.label`
  ${LabelStyle}

  &:first-child {
    margin-left: 0;
  }

  ${(props: { checked: boolean }) =>
    props.checked &&
    css`
      border: 2px solid #561496;
      background-color: #e8e6eb;
    `}
`

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 15px;
`

const Label = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
  text-align: left;
`

export default memo(
  ({
    initialAmount = 0,
    label,
    setValue,
    register,
    ...props
  }: {
    initialAmount?: number
    label?: string
    register: any
    setValue: (name, value, options) => void
  }) => {
    const [isCustom, setIsCustom] = useState(false)
    const [amount, setAmount] = useState(initialAmount)

    useEffect(() => {
      setValue("amount", amount, { shouldDirty: true })
    }, [amount])

    const handleChange = useCallback(
      e => {
        switch (e.target.type) {
          case "radio":
            setIsCustom(false)
            break
        }
        setAmount(Number(e.target.value))
      },
      [setIsCustom, setAmount]
    )

    const handleClickCustom = useCallback(
      e => {
        setIsCustom(true)
      },
      [setIsCustom]
    )

    return (
      <Self {...props}>
        {label && <Label>{label}</Label>}
        <OptionsWrapper>
          {AMOUNTS.map(a => (
            <FixedAmountLabel
              checked={!isCustom && amount === a}
              htmlFor={`amount-${a}`}
            >
              <FixedAmountInput
                checked={!isCustom && amount === a}
                id={`amount-${a}`}
                name="amount"
                type="radio"
                value={a}
                onChange={handleChange}
                ref={register}
                key={a}
              />
              {a.toLocaleString()}원
            </FixedAmountLabel>
          ))}

          <CustomAmountButton checked={isCustom} onClick={handleClickCustom}>
            기타
          </CustomAmountButton>
        </OptionsWrapper>

        {isCustom && (
          <CustomAmountWrapper>
            <CustomAmountHelpText>11,000원부터 가능합니다</CustomAmountHelpText>
            <CustomAmountInputWrapper>
              <CustomAmountInput
                name="amount"
                onChange={handleChange}
                type="number"
                defaultValue={11000}
                register={register}
              />
            </CustomAmountInputWrapper>
          </CustomAmountWrapper>
        )}
      </Self>
    )
  }
)
