import React, { memo, useCallback, useEffect, useRef, useState } from "react"
import { useQueryParam, NumberParam } from "use-query-params"
import { graphql } from "gatsby"

import JoinForm from "../components/join-form"
import Jumbotron from "../components/jumbotron"
import Layout from "../components/layout"
import PleaseJoin from "../components/please-join"
import PleasePlastic from "../components/please-plastic"
import SEO from "../components/seo"
import styled from "../context/theme"
import { keyframes } from "@emotion/core"
import carousel from "../components/carousel"

const StyledJoinForm = styled(JoinForm)`
  z-index: 100;
`

const ToggleButtonWrapper = styled.div`
  z-index: 10;
  position: relative;
  height: 0;
`

const PleaseClickAnimation = keyframes`
0%, 100% {
  top: -85px
}

50% {
  top: -75px;
}
`

const PleaseClick = styled.div`
  background-image: url("images/clickable-arrow-bottom.svg");
  background-size: cover;
  width: 13px;
  height: 17px;
  position: absolute;
  top: -80px;
  left: 0;
  right: 0;
  margin: 0 auto;
  animation: ${PleaseClickAnimation} 1s ease-in-out infinite;
`

const ToggleButton = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100px;
  height: 100px;
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  border-radius: 50px;
  cursor: pointer;
  white-space: pre-wrap;
  background-color: #6c19bc;
  font-weight: 500;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
`

const Index: React.FC = memo(() => {
  const [joinOpened, setJoinOpened] = useState(false)
  const toggleButtonRef = useRef(null)
  const [startTime] = useQueryParam("t", NumberParam)

  useEffect(() => {
    window.location.href =
      "https://www.youtube.com/watch?v=3luW934Kknw&list=PLo4PbCH1mmVc2H2zBnsTFCy-crRgw5dA6"
  }, [])

  const handleClickToggleButton = useCallback(() => {
    setJoinOpened(!joinOpened)
  }, [joinOpened, setJoinOpened])

  return (
    <Layout>
      <SEO />
      <Jumbotron start={startTime} />
      <PleasePlastic />
      <ToggleButtonWrapper ref={toggleButtonRef}>
        <PleaseClick />
        <ToggleButton className="ga-cta" onClick={handleClickToggleButton}>
          닷페이스
          <br />
          후원하기
        </ToggleButton>
      </ToggleButtonWrapper>
      <StyledJoinForm opened={joinOpened} />
      <PleaseJoin />
    </Layout>
  )
})

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
