import React, { memo } from "react"

import { InnerSection as InnerSectionOrig, SectionWrapper } from "./common"
import styled from "../context/theme"

const Self = styled(SectionWrapper)`
  padding: 25px 0px;
`

const Description = styled.p`
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  margin-top: 20px;
  word-break: keep-all;
  text-align: left;
`

const InnerSection = styled(InnerSectionOrig)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 53px 15px 50px;
  label: DotfacePeople;
`

const Title = styled.h3`
  color: #222026;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;

  &:not(:nth-of-type(1)) {
    margin-top: 30px;
  }
`

export default memo(() => {
  return (
    <Self>
      <InnerSection className="ga-scroll" data-ga-section="닷페피플 알아보기">
        <Title>우리가 살아갈 세상은 달라야 하니까</Title>
        <Description>
          우리는 변화가 필요한 현실을 이야기합니다. 닷페이스는 기후 위기, 디지털
          성폭력, 차별과 혐오의 문제 등에 집중해온 미디어입니다.
        </Description>
        <Title>닷페이스를 움직이는 힘, 닷페피플</Title>
        <Description>
          우리의 이야기는 후원자들의 힘으로 만들어집니다. 닷페이스를 후원하는
          닷페피플이 되시면 불평등과 사회 문제를 깊이 다루는 콘텐츠 제작에
          기여할 수 있습니다.월 11,000원부터 정기 후원을 시작하실 수 있습니다.
        </Description>
      </InnerSection>
    </Self>
  )
})
