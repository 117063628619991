const API_HOST = process.env.GATSBY_API_HOST

export const fetchStories = () => {
  return fetch(`${API_HOST}/etc/instagram-stories`).then(res => res.json())
}

export const checkEmailExists = (email: string) => {
  var formData = new FormData()
  formData.append("email", email)

  return fetch(`${API_HOST}/api/user/_exists`, {
    body: formData,
    credentials: "same-origin",
    method: "POST",
  }).then(res => res.json())
}

export const joinIntegrated = (data: {
  amount: string
  cardNumber: string
  cardBirth: string
  cardExpiry: string
  cardPassword: string
  date: number
  email: string
  nickname: string
  phone: string
}) => {
  var formData = new FormData()
  Object.keys(data).forEach(key => {
    formData.append(key, data[key])
  })

  return fetch(`${API_HOST}/api/membership/integrated`, {
    body: formData,
    credentials: "same-origin",
    method: "POST",
  }).then(res => res.json())
}

export const joinIntegratedContinue = (data: {
  address_append: string
  address_common: string
  postal_code: string
  name: string
  token: string
}) => {
  var formData = new FormData()
  Object.keys(data).forEach(key => {
    formData.append(key, data[key])
  })

  return fetch(`${API_HOST}/api/membership/continue`, {
    body: formData,
    credentials: "same-origin",
    method: "POST",
  }).then(res => res.json())
}

export const subscribeSteebie = (nickname: string, email: string) => {
  var formData = new FormData()
  formData.append("nickname", nickname)
  formData.append("email", email)
  return fetch(
    "https://stibee.com/api/v1.0/lists/SLSbRri_RO20gOfeJFUq9r3P2Ilt/public/subscribers",
    {
      body: formData,
      credentials: "same-origin",
      method: "POST",
    }
  ).then(res => res.text())
}
