import { css, SerializedStyles } from "@emotion/core"
import React, { InputHTMLAttributes } from "react"
import styled from "../../context/theme"

const DefaultButtonStyle = css`
  background: #f7f6f9;
  border: 1px solid #a8a4ac;
  box-sizing: border-box;
  border-radius: 4px;
  white-space: nowrap;
`

const Self = styled.div`
  text-align: left;
`

const Button = styled.button`
  ${DefaultButtonStyle}

  margin-left: 10px;
`

const ErrorMessage = styled.div`
  font-size: 12px;
  line-height: 22px;
  color: #eb0000;
  text-align: left;
  margin-top: 3px;
`

const Input = styled.input`
  outline: 0;
  border: none;
  width: 100%;
  background-color: #f8f7fa;
  border-bottom: 1px solid #c7c7c7;
  width: 100%;
  height: 32px;
  font-size: 14px;

  &:disabled {
    background-color: #e8e6eb;
  }
`

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const Label = styled.label`
  font-size: 16px;
  line-height: 22px;
  color: #353535;
`

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  register?: React.Ref<HTMLInputElement>
}

const DfInput: React.FC<
  InputProps & {
    buttonAction?: () => void
    buttonText?: string
    errorMessage?: string
    inputStyle?: SerializedStyles
    labelStyle?: SerializedStyles
    withButton?: boolean
  }
> = ({
  buttonAction,
  buttonText,
  className,
  errorMessage,
  inputStyle,
  label,
  labelStyle,
  register,
  withButton,
  ...props
}) => {
  return (
    <Self className={className}>
      {label && <Label css={labelStyle}>{label}</Label>}

      {withButton ? (
        <InputWrapper>
          <Input css={inputStyle} ref={register} {...props} />
          <Button onClick={buttonAction} type="button">
            {buttonText}
          </Button>
        </InputWrapper>
      ) : (
        <Input css={inputStyle} ref={register} {...props} />
      )}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Self>
  )
}

export default DfInput
