import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useForm } from "react-hook-form"
import TagManager from "react-gtm-module"
import * as yup from "yup"
import { css } from "@emotion/core"
import { yupResolver } from "@hookform/resolvers/yup"

import { InnerSection as InnerSectionOrig, SectionWrapper } from "./common"
import { Modal as ModalOrig } from "./modals"
import AddressForm from "./form/address"
import PaymentAmountOrig from "./form/payment-amount"
import Input from "./form/input"
import styled from "../context/theme"
import { useModalStore } from "../context/stores"
import {
  checkEmailExists,
  joinIntegrated,
  joinIntegratedContinue,
} from "../utils/api"

const logGTM = {
  step: (step, etc = "") => {
    TagManager.dataLayer({
      dataLayer: {
        event: "landingPeopleJoinStep",
        payment: [
          {
            category: "plastic.dotface.kr",
            step,
            etc,
          },
        ],
      },
    })
  },
  purchace: ({ amount, merchantUid }) => {
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: {
          purchase: {
            actionField: {
              id: merchantUid,
              revenue: amount,
            },
            products: [
              {
                name: "People - Landing",
                id: "people",
                price: amount,
                category: "닷페피플",
                variant: "멤버십-정기결제",
                quantity: 1,
              },
            ],
          },
        },
      },
    })
  },
  purchaceStep: (amount = 11000) => {
    TagManager.dataLayer({
      dataLayer: {
        event: "landingPeopleJoinStep",
        payment: [
          {
            amount,
            category: "plastic.dotface.kr",
            step: logGTM.STEPS.STEP_2,
            etc: "",
          },
        ],
      },
    })
  },
  STEPS: {
    STEP_1: "1",
    STEP_1_FAILED: "1_DUPLICATED_EMAIL",
    STEP_2: 2,
    STEP_2_FAILED: "2_FAILED",
    STEP_3: 3,
  },
}

const FullButtonStyle = css`
  background-color: #561396;
  color: white;
  border-radius: 4px;
  width: 100%;
  padding: 10px;
`

const Self = styled(SectionWrapper)`
  background-color: #f8f7fa;
  padding-right: 24px;
  padding-left: 24px;
  transition: max-height 0.2s ease-out;

  ${(props: { opened: boolean }) =>
    props.opened
      ? css`
          overflow: hidden;
          max-height: 700px;
          transition: max-height 0.2s ease-in;
        `
      : css`
          max-height: 0;
          overflow: hidden;
        `}
`

const InputLabelStyle = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #353535;
  text-align: left;
  margin-bottom: 10px;

  &:not(:nth-of-type(1)) {
    margin-top: 30px;
  }
`

const InputLabel = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #353535;
  text-align: left;
  margin-bottom: 10px;

  &:not(:nth-of-type(1)) {
    margin-top: 30px;
  }
`

const Form = styled.form`
  position: relative;
`

const CardFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  background-color: white;
  border: 1px solid #a8a4ac;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 14px 20px;
`

const CardInputStyle = css`
  background-color: white;
`

const CardLabelStyle = css`
  color: #353535;
  font-size: 12px;
  line-height: 22px;
`

const CardNumberExpiryRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const CardNumberInput = styled(Input)`
  width: calc(100% * 2 / 3 - 5px);
`

const CardExpiryInput = styled(Input)`
  width: calc(100% / 3 - 5px);
`

const CardPasswordBirthRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const CardPasswordInput = styled(Input)`
  width: calc(100% / 3 - 5px);
`

const CardBirthInput = styled(Input)`
  width: calc(100% * 2 / 3 - 5px);
`

const Modal = styled(ModalOrig)`
  background-color: ${({ theme }) => theme.colors.white};
`

const EmailInput = styled(Input)``

const PhoneNickNameRow = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`

const PhoneNickNameColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% / 2 - 10px);
`

const NickNameInput = styled(Input)``

const PhoneInput = styled(Input)``

const PaymentAmount = styled(PaymentAmountOrig)`
  margin-top: 10px;
`

const InnerSection = styled(InnerSectionOrig)`
  position: relative;
  padding-top: 43px;
  padding-bottom: 46px;
  label: SubscriptionFormSection;
`

const SubmitButton = styled.button`
  ${FullButtonStyle}
`

const SubmitButtonHelpText = styled.div`
  font-size: 9px;
  line-height: 18px;
  text-align: center;
  color: #353535;
  margin-bottom: 4px;
`

const SubmitButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`

const AddressSubmitButton = styled.button`
  ${FullButtonStyle}
`

const SuccessDotNyangWrapper = styled.div`
  position: relative;
`

const AddressModalTitle = styled.h2`
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #353535;
  margin-bottom: 50px;
`

const HappyDotCatTitle = styled.h2`
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #5d0094;
  margin-bottom: 0px;
`

const HappyDotCatWrapper = styled.div`
  padding: 20%;
`

const HappyDotCat = styled.div`
  background-image: url("images/happy-dotcat.png");
  background-size: cover;
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  position: relative; /* If you want text inside of it */
`

const HappyDotCatButton = styled.button`
  ${FullButtonStyle}
`

const AfterJoinModal: React.FC<{
  nickname: string
  onAfterSuccess?: () => void
  token: string
}> = memo(({ nickname, token }) => {
  console.log(nickname)
  const [submitSucceeded, setSubmitSucceeded] = useState(false)
  const [loading, setLoading] = useState(false)
  const { closeModal } = useModalStore()

  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup
          .string()
          .required("필수 정보입니다.")
          .max(10, "이름은 10자 이내로 입려해주세요."),
        postal_code: yup.string(),
        address_common: yup.string(),
        address_append: yup.string(),
      }),
    []
  )

  const {
    errors,
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  })

  watch()
  const values = getValues()

  const handleAddressSearch = useCallback(
    data => {
      setValue("address_common", data.roadAddress)
      setValue("postal_code", data.zonecode)
    },
    [setValue]
  )

  const handleClickDone = useCallback(() => {
    closeModal()
  }, [closeModal])

  const onSubmit = useCallback(async () => {
    setSubmitSucceeded(true)
    const values = getValues() as {
      address_common: string
      address_append: string
      postal_code: string
      name: string
    }
    setLoading(true)
    try {
      const result = await joinIntegratedContinue({
        ...values,
        token,
      })

      setLoading(false)

      if (result.success) {
        logGTM.step(logGTM.STEPS.STEP_3)
        setSubmitSucceeded(true)
        return
      }

      if (result.message) {
        window.alert(result.message)
      } else {
        window.alert(
          Object.values(result.errors).join("\n") ||
            "알 수 없는 에러가 발생했습니다. 다시 시도해주세요."
        )
      }
    } catch (e) {
      window.alert("알 수 없는 에러가 발생했습니다. 다시 시도해주세요.")
      setLoading(false)
    }
  }, [values])

  return (
    <Modal>
      {submitSucceeded ? (
        <SuccessDotNyangWrapper>
          <HappyDotCatTitle>
            {nickname} 님은🎉
            <br />
            이제부터 닷페피플입니다!
          </HappyDotCatTitle>
          <HappyDotCatWrapper>
            <HappyDotCat />
          </HappyDotCatWrapper>
          <HappyDotCatButton onClick={handleClickDone}>확인</HappyDotCatButton>
        </SuccessDotNyangWrapper>
      ) : (
        <>
          <AddressModalTitle>
            닷페레터 받을 주소만 입력하면 끝!
          </AddressModalTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <AddressForm
              address_common={values.address_common}
              errors={errors}
              postal_code={values.postal_code}
              onSearch={handleAddressSearch}
              register={register}
            />

            <AddressSubmitButton
              disabled={
                loading || !(values.address_common && values.postal_code)
              }
              type="submit"
            >
              진짜 완료!
            </AddressSubmitButton>
          </form>
        </>
      )}
    </Modal>
  )
})

const GoToLoginLink = styled.a`
  display: block;
  width: 100%;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.purple};
  color: white;
`

const GoToLoginButton = memo(() => {
  return (
    <GoToLoginLink
      href="https://dotface.kr/user/login?next=%2Fmembership%2Fjoin"
      target="_blank"
    >
      로그인 하러가기
    </GoToLoginLink>
  )
})

export default memo(({ opened = false }: { opened: boolean }) => {
  const [cachedNickname, setCachedNickname] = useState("")
  const { openModal } = useModalStore()
  const [submitEnabled, setSubmitEnabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const selfRef = useRef(null)

  const handleTransitionEnd = useCallback(() => {
    if (opened) {
      const offsetTop = selfRef.current.offsetTop
      window.scrollTo({
        top: offsetTop,
        left: 0,
        behavior: "smooth",
      })
    }
  }, [opened])

  const validationSchema = useMemo(
    () =>
      yup.object({
        amount: yup.number().min(11000).required("필수 정보입니다."),
        email: yup
          .string()
          .required("필수 항목입니다.")
          .email("올바른 이메일 형식으로 입력해주세요."),
        nickname: yup
          .string()
          .required("필수 항목입니다.")
          .max(10, "닉네임은 10자 이내로 입력해주세요."),
        phone: yup
          .string()
          .required("필수 항목입니다.")
          .min(9, "휴대폰번호를 정확히 입력해주세요.")
          .length(11, "휴대폰번호를 정확히 입력해주세요."),
        card_number: yup
          .string()
          .required("필수 항목입니다.")
          .min(14, "카드번호를 확인해주세요.")
          .length(16, "카드번호를 확인해주세요."),
        card_expiry: yup
          .string()
          .required("필수 항목입니다.")
          .length(4, "카드유효기간을 확인해주세요."),
        card_password: yup
          .string()
          .required("필수 항목입니다.")
          .length(2, "비밀번호 앞 2자리를 확인해주세요."),
        card_birthday: yup
          .string()
          .required("필수 항목입니다.")
          .length(6, "생년월일 6자리를 확인해주세요."),
      }),
    []
  )

  const {
    register,
    handleSubmit,
    clearErrors,
    errors,
    getValues,
    setError,
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  })

  const values = getValues()
  watch()
  useEffect(() => {
    if (
      Object.keys(errors).length === 0 &&
      !Object.values(values).filter(v => !v).length
    ) {
      setSubmitEnabled(true)
    } else {
      setSubmitEnabled(false)
    }
  }, [errors, values])

  useEffect(() => {
    if (values.nickname) {
      setCachedNickname(values.nickname)
    }
  }, [values.nickname])

  const handleBlurEmail = useCallback(async () => {
    try {
      const result = await checkEmailExists(getValues("email"))

      if (!result.success && !!result.errors.user[0]) {
        logGTM.step(logGTM.STEPS.STEP_1_FAILED)

        setError("email", {
          type: "manual",
          message: "이미 닷페이스 웹사이트 계정이 있습니다. 로그인해주세요.",
        })
      } else {
        logGTM.step(logGTM.STEPS.STEP_1)

        clearErrors("email")
      }
    } catch (e) {}
  }, [checkEmailExists, getValues, setError])

  const onSubmit = useCallback(
    async data => {
      setLoading(true)
      try {
        const result = await joinIntegrated(data)
        setLoading(false)

        if (result.success) {
          logGTM.purchace({
            amount: data.amount,
            merchantUid: result.data.merchant_uid,
          })
          logGTM.purchaceStep(data.amount)

          openModal(
            <AfterJoinModal
              nickname={cachedNickname}
              token={result.data.token}
            />
          )
          return
        }

        if (result.message) {
          logGTM.step(logGTM.STEPS.STEP_2_FAILED, result.message)
          window.alert(result.message)
        } else {
          logGTM.step(
            logGTM.STEPS.STEP_2_FAILED,
            Object.values(result.errors).join("\n") ||
              "알 수 없는 에러가 발생했습니다. 다시 시도해주세요."
          )

          window.alert(
            Object.values(result.errors).join("\n") ||
              "알 수 없는 에러가 발생했습니다. 다시 시도해주세요."
          )
        }
      } catch (e) {
        window.alert("알 수 없는 에러가 발생했습니다. 다시 시도해주세요.")
        setLoading(false)
      }
    },
    [cachedNickname, joinIntegrated, setLoading]
  )

  return (
    <Self
      opened={opened}
      register={selfRef}
      onTransitionEnd={handleTransitionEnd}
    >
      <InnerSection>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputLabel>이메일</InputLabel>
          <EmailInput
            errorMessage={errors.email?.message}
            name="email"
            onBlur={handleBlurEmail}
            register={register}
            type="email"
          />
          {errors.email?.type === "manual" && <GoToLoginButton />}

          <PhoneNickNameRow>
            <PhoneNickNameColumn>
              <InputLabel>휴대폰</InputLabel>
              <PhoneInput
                errorMessage={errors.phone?.message}
                maxLength={11}
                minLength={9}
                name="phone"
                placeholder="-없이 입력해주세요"
                register={register}
                type="tel"
              />
            </PhoneNickNameColumn>

            <PhoneNickNameColumn>
              <InputLabel>닉네임</InputLabel>
              <NickNameInput
                errorMessage={errors.nickname?.message}
                maxLength={10}
                minLength={1}
                name="nickname"
                placeholder="10자리 이내"
                register={register}
              />
            </PhoneNickNameColumn>
          </PhoneNickNameRow>

          <InputLabel>매월 결제 금액</InputLabel>
          <PaymentAmount register={register} setValue={setValue} />

          <InputLabel>결제 카드</InputLabel>
          <CardFormWrapper>
            <CardNumberExpiryRow>
              <CardNumberInput
                autoComplete="cc-number"
                errorMessage={errors.card_number?.message}
                label="카드번호"
                name="card_number"
                inputMode="numeric"
                inputStyle={CardInputStyle}
                labelStyle={CardLabelStyle}
                maxLength={16}
                minLength={14}
                placeholder="-없이 입력해주세요"
                register={register}
              />

              <CardExpiryInput
                autoComplete="cc-exp"
                errorMessage={errors.card_expiry?.message}
                label="유효기간 4자리"
                name="card_expiry"
                inputMode="numeric"
                inputStyle={CardInputStyle}
                labelStyle={CardLabelStyle}
                maxLength={4}
                placeholder="MMYY"
                register={register}
              />
            </CardNumberExpiryRow>

            <CardPasswordBirthRow>
              <CardPasswordInput
                errorMessage={errors.card_password?.message}
                label="비밀번호 앞 2자리"
                name="card_password"
                inputMode="numeric"
                inputStyle={CardInputStyle}
                labelStyle={CardLabelStyle}
                type="password"
                maxLength={2}
                minLength={2}
                register={register}
              />
              <CardBirthInput
                autoComplete="bday"
                errorMessage={errors.card_birthday?.message}
                label="생년월일 6자리"
                name="card_birthday"
                inputMode="numeric"
                inputStyle={CardInputStyle}
                labelStyle={CardLabelStyle}
                maxLength={6}
                minLength={6}
                register={register}
              />
            </CardPasswordBirthRow>
          </CardFormWrapper>

          <SubmitButtonWrapper>
            <SubmitButtonHelpText>
              위 결제 내용을 확인했으며, 월 결제에 동의합니다.{" "}
            </SubmitButtonHelpText>
            <SubmitButton disabled={loading || !submitEnabled} type="submit">
              닷페피플 가입하기
            </SubmitButton>
          </SubmitButtonWrapper>
        </Form>
      </InnerSection>
    </Self>
  )
})
