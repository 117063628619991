import React, { memo, useCallback, useEffect, useRef } from "react"
import SliderOrig from "react-slick"
import { css, keyframes } from "@emotion/core"
import { InnerSection as InnerSectionOrig, SectionWrapper } from "./common"
import styled from "../context/theme"
import { setDigitLength } from "../utils/number"

import "../styles/slick.css"
import "../styles/slick-theme.css"
import { useNotificationStore } from "../context/stores"

export type Scene = {
  timeFrom: number
  title: string
  description: string
}

const Self = styled(SectionWrapper)`
  width: 100%;
  padding: 20px 30px 50px;
  label: Carousel;
`

const InnerSection = styled(InnerSectionOrig)`
  width: 100%;
`

const ArrowStyle = css`
  display: block;
  background-size: cover;
  background-repeat: none;
  width: 10px;
  height: 20px;
  z-index: 10;
  position: initial;

  &::before {
    content: "";
  }
`

const ArrowWrapperStyle = css`
  position: absolute;
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 50px;
  z-index: 10;
  top: 81px;
  cursor: pointer;
`

const ArrowLeftWrapper = styled.div`
  ${ArrowWrapperStyle}
  left: 0;
`

const ArrowRightWrapper = styled.div`
  ${ArrowWrapperStyle}
  right: 0;
`

const StyledArrowLeft = styled.div`
  ${ArrowStyle};
  background-image: url("images/arrow-left.svg");

  &:hover {
    ${ArrowStyle};
    background-image: url("images/arrow-left.svg");
  }
`

const StyledArrowRight = styled.div`
  ${ArrowStyle};
  background-image: url("images/arrow-right.svg");

  &:hover {
    ${ArrowStyle};
    background-image: url("images/arrow-right.svg");
  }
`

const ItemSelf = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 25px;
  label: ItemSelf;
`

const ItemDescription = styled.p`
  margin-top: 20px;
  margin-bottom: 30px;
  white-space: pre-wrap;
  word-break: keep-all;
  text-align: left;
  label: ItemDescription;
`

const ItemTitle = styled.h3`
  white-space: pre-wrap;
  label: ItemTitle;
`

const ItemTitleWrapper = styled.h3`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 23px;
  margin-top: 23px;
  white-space: pre-wrap;
  min-height: 45px;
  label: ItemTitleWrapper;
`

const PlayButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 84px;
  background-color: #561396;
  border-radius: 4px;
  margin-top: 20px;
`

const PlayIcon = styled.div`
  background-image: url("images/play.svg");
  background-size: cover;
  width: 9px;
  height: 11.5px;
  margin-right: 5px;
`

const Slider = styled(SliderOrig)`
  label: Slider;
/* 
  ${(props: { page: number }) => css`
    ${props.page === 0 && `max-height: 390px;`}
    ${props.page === 1 && `max-height: 400px;`}
    ${props.page === 2 && `max-height: 390px;`}
    ${props.page === 3 && `max-height: 410px;`}
    ${props.page === 4 && `max-height: 440px;`}
  `}

  @media (max-width: 400px) {
    ${(props: { page: number }) => css`
      ${props.page === 0 && `max-height: 410px;`}
      ${props.page === 1 && `max-height: 420px;`}
      ${props.page === 2 && `max-height: 410px;`}
      ${props.page === 3 && `max-height: 500px;`}
      ${props.page === 4 && `max-height: 460px;`}
    `}
  }

  @media (min-width: ${props => props.theme.size.maxWidth}px) {
    ${(props: { page: number }) => css`
      ${props.page === 0 && `max-height: 370px;`}
      ${props.page === 1 && `max-height: 380px;`}
      ${props.page === 2 && `max-height: 370px;`}
      ${props.page === 3 && `max-height: 380px;`}
      ${props.page === 4 && `max-height: 410px;`}
    `}
  } */

`

const SliderWrapper = styled.div`
  width: 100%;
`

const Item = memo(
  ({
    description,
    onClickTime,
    timeFrom,
    title,
  }: {
    description: string
    onClickTime?: (time) => void
    page?: number
    timeFrom: number
    title: string
  }) => {
    const handleClickTimeRange = useCallback(() => {
      if (onClickTime) {
        onClickTime(timeFrom)
      }
    }, [onClickTime])

    return (
      <ItemSelf>
        <ItemTitleWrapper>
          <ItemTitle>{title}</ItemTitle>
        </ItemTitleWrapper>
        <PlayButton className="ga-playBtn" onClick={handleClickTimeRange}>
          <PlayIcon /> {setDigitLength(Number((timeFrom / 60).toFixed(0)), 2)}:
          {setDigitLength(Number((timeFrom % 60).toFixed(0)), 2)}
        </PlayButton>
        <ItemDescription>{description}</ItemDescription>
      </ItemSelf>
    )
  }
)

const ArrowRight = memo(props => {
  return (
    <ArrowRightWrapper {...props}>
      <StyledArrowRight />
    </ArrowRightWrapper>
  )
})

const ArrowLeft = memo(props => {
  return (
    <ArrowLeftWrapper {...props}>
      <StyledArrowLeft />
    </ArrowLeftWrapper>
  )
})

const increateAnim = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 20px;
  }
`

const DotSelf = styled.a`
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #c4c4c4;

  ${(props: { currentPage: number; page: number; totalPage: number }) => css`
    ${props.page <= props.currentPage && `background-color: #561496`};

    ${props.page !== 0 &&
    props.page <= props.currentPage &&
    css`
      &::after {
        content: "";
        background-color: #561496;
        width: 20px;
        height: 3px;
        display: inline-block;
        position: absolute;
        left: -20px;
        top: 3.5px;
        z-index: 10;
      }
    `};

    ${props.page === props.currentPage &&
    css`
      &::after {
        animation-name: ${increateAnim};
        animation-duration: 0.5s;
        animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
      }
    `}

    ${props.page > 0 &&
    css`
      &::before {
        content: "";
        background-color: #c4c4c4;
        width: 20px;
        height: 3px;
        display: inline-block;
        position: absolute;
        left: -20px;
        top: 3.5px;
        background-size: 200%;
        transition: 0.5s ease-out;
      }
    `};
  `}
`

const Dot = ({
  currentPage,
  page,
  totalPage,
}: {
  currentPage: number
  page: number
  totalPage: number
}) => {
  return <DotSelf currentPage={currentPage} page={page} totalPage={totalPage} />
}

const ShareButton = styled.button`
  background: #f8f7fa;
  font-weight: 400;
  border: 1px solid #a8a4ac;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  margin-top: 50px;
  padding: 8px;
`

export default memo(
  ({
    className,
    onChangePage,
    onPlayTime,
    page,
    scenes,
  }: {
    className?: string
    onChangePage: (page) => void
    onPlayTime: (number) => void
    page?: number
    scenes: Scene[]
  }) => {
    const sliderRef = useRef(null)
    const { showNotification } = useNotificationStore()

    useEffect(() => {
      if (typeof page === "number" && sliderRef.current) {
        sliderRef.current.slickGoTo(page)
      }
    }, [page])

    const handleClickShare = useCallback(() => {
      var tempElem = document.createElement("textarea")
      switch (page) {
        case 0:
          tempElem.value = "https://dotf.kr/2JdHbtr"
          break
        case 1:
          tempElem.value = "https://dotf.kr/2HJajsc"
          break
        case 2:
          tempElem.value = "https://dotf.kr/2TyL5Pz"
          break
        case 3:
          tempElem.value = "https://dotf.kr/3oE0xYU"
          break
        case 4:
          tempElem.value = "https://dotf.kr/37QpBG5"
          break
        case 5:
          tempElem.value = "https://dotf.kr/2TDgxMo"
          break
        case 6:
          tempElem.value = "https://dotf.kr/3mxFMwm"
          break
      }
      document.body.appendChild(tempElem)

      tempElem.select()
      document.execCommand("copy")
      document.body.removeChild(tempElem)
      showNotification("링크가 복사되었습니다.")
    }, [page])

    const handleClickTime = useCallback(time => {
      onPlayTime(time)
    }, [])

    const onBeforeChange = useCallback(
      (c: number, next: number) => {
        onChangePage(next)
      },
      [onChangePage]
    )

    const slickConfig = {
      arrows: true,
      autoPlay: false,
      dots: true,
      draggable: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <ArrowRight />,
      prevArrow: <ArrowLeft />,
      customPaging: i => {
        return (
          <Dot currentPage={page} key={i} page={i} totalPage={scenes.length} />
        )
      },
      beforeChange: onBeforeChange,
    }

    return (
      <Self className={className}>
        <InnerSection>
          <SliderWrapper>
            <Slider page={page} ref={sliderRef} {...slickConfig}>
              {scenes.map((scene, index) => (
                <Item
                  description={scene.description}
                  onClickTime={handleClickTime}
                  key={index}
                  title={scene.title}
                  timeFrom={scene.timeFrom}
                />
              ))}
            </Slider>
          </SliderWrapper>

          <ShareButton className="ga-cta" onClick={handleClickShare}>
            공유하기
          </ShareButton>
        </InnerSection>
      </Self>
    )
  }
)
